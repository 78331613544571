<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h3 class="ng-block-title fw-normal" v-if="agent_id">
            Agent: {{ agent?.first_name }}
          </h3>
          <h2 class="ng-block-title fw-normal" v-else>
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des">
            <h6>
              Shipments for week
              {{ GetWeek(start) }}
              (<b>
                {{ fD(start, "E, do MMM") }}
              </b>
              -
              <b> {{ fD(end, "E, do MMM") }} </b>)
            </h6>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li v-if="agents.length">
              <div class="input-group">
                <div class="input-group-prepend d-none d-sm-flex">
                  <span
                    class="input-group-text bg-primary text-white font-weight-bold"
                  >
                    Agent
                  </span>
                </div>
                <Multiselect
                  :options="agents"
                  v-model="agent_id"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Start typing..."
                  @change="fetchShipments"
                />
              </div>
            </li>
            <li v-if="agent_id">
              <a
                href="#pickDates"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Week</span>
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="pickDates">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">
                        Select Week Start Date
                      </h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="start" />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Starting on</h5>
                        <p>
                          {{ fD(start) }}
                        </p>

                        <h5>Ending on</h5>
                        <p>
                          {{ fD(end) }}
                        </p>

                        <button
                          class="btn btn-outline-primary float-right"
                          data-dismiss="modal"
                        >
                          <span> Use Selected Dates </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>
      <div class="card" v-else>
        <div class="card-content pmd-table-card" v-if="agent_id">
          <table
            class="table table-tranx pmd-table"
            v-if="shipments.data.length > 0"
          >
            <thead class="tb-tnx-head">
              <tr class="tb-tnx-item">
                <th class="text-left align-middle">
                  Date
                </th>
                <th class="text-left align-middle">Shipper</th>
                <th class="text-left align-middle">Customer/Consignee</th>
                <th class="text-left align-middle">Destination</th>
                <th class="text-left align-middle">Forwarder</th>
                <th class="text-left align-middle">Total Boxes</th>
                <th class="text-left align-middle">Total Pallets</th>
                <th class="text-left align-middle">Net Total Kgs</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(details, i) in shipments.data"
                :key="i"
                class="tb-tnx-item"
              >
                <td class="tb-tnx-info align-middle">
                  {{ fD(new Date(details.date), "E, do MMMM yyyy") }}
                </td>
                <td class="tb-tnx-info align-middle">{{ details.shipper }}</td>
                <td class="tb-tnx-info align-middle">
                  {{ details.customer?.first_name }}
                </td>
                <td class="tb-tnx-info align-middle">
                  {{ details.location }}
                </td>
                <td class="tb-tnx-info align-middle">
                  {{ details.agent?.first_name }}
                </td>
                <td class="tb-tnx-info text-center">
                  {{ addCommas(details.boxes) }}
                </td>
                <td class="tb-tnx-info text-center">
                  {{ addCommas(details.pallets) }}
                </td>
                <td class="tb-tnx-info text-center">
                  {{ addCommas(details.weight) }}
                </td>
              </tr>
            </tbody>
            <tfoot class="tb-tnx-head">
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle" colspan="2">
                  <b class="text-left"> TOTAL </b>
                </th>
                <th colspan="3"></th>
                <th class="text-center align-middle">
                  {{ addCommas(shipments.total?.boxes) }}
                </th>
                <th class="text-center align-middle">
                  {{ addCommas(shipments.total?.pallets) }}
                </th>
                <th class="text-center align-middle">
                  {{ addCommas(shipments.total?.weight) }}
                </th>
              </tr>
            </tfoot>
          </table>
          <table class="table table-tranx border-0" v-else>
            <tr class="tb-odr-item alert alert-danger border-0">
              <td colspan="9" class="text-center">
                <h4>
                  <em class="icon ni ni-alert-c text-danger mr-2"></em>
                  Agent {{ agent.first_name }} {{ agent.last_name }} has no
                  shipments on week {{ GetWeek(start) }}
                </h4>
              </td>
            </tr>
          </table>
        </div>
        <div class="alert alert-warning text-center" v-else>
          <h5>
            <em class="icon ni ni-alert text-warning"></em>
          </h5>
          Please select an agent to view shipment details.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import NgongVegBanner from "@/components/NgongVegBanner";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { format } from "date-fns";
import http from "../../../../../common/services/http";
import router from "@/router";
export default defineComponent({
  name: "AgentShipments",
  components: { NgongVegBanner },
  setup(props) {
    const processing = ref(false);

    const agents = ref([]);
    const agent_id = ref(0);
    const agent = computed(() => {
      return agents.value?.find((c: any) => c.id == agent_id.value);
    });

    function friday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 3))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 3));
    }

    const start = ref(friday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    const shipments = ref({
      data: [],
      total: {}
    });

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = value.toFixed(0);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    function fetchAgents() {
      processing.value = true;
      http.get(`/api/users?role=agent&fetch=all`).then(res => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function fetchShipments(id: any) {
      processing.value = true;

      http
        .get(
          `/api/agent-shipments?agent=${id}&start=${fD(
            start.value,
            "yyyy-MM-dd"
          )}&end=${fD(end.value, "yyyy-MM-dd")}`
        )
        .then(res => {
          console.log(res.data);
          shipments.value = res.data;
          processing.value = false;
        });
    }

    onMounted(() => {
      fetchAgents();
    });

    watch(
      () => end.value,
      (v: any) => {
        fetchShipments(agent_id.value);
      }
    );

    return {
      agent_id,
      agent,
      agents,
      start,
      end,
      fD,
      processing,
      shipments,
      fetchShipments,
      addCommas
    };
  }
});
</script>

<style scoped></style>
