
import NgongVegBanner from "@/components/NgongVegBanner";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { format } from "date-fns";
import http from "../../../../../common/services/http";
import router from "@/router";
export default defineComponent({
  name: "AgentShipments",
  components: { NgongVegBanner },
  setup(props) {
    const processing = ref(false);

    const agents = ref([]);
    const agent_id = ref(0);
    const agent = computed(() => {
      return agents.value?.find((c: any) => c.id == agent_id.value);
    });

    function friday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 3))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 3));
    }

    const start = ref(friday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    const shipments = ref({
      data: [],
      total: {}
    });

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = value.toFixed(0);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    function fetchAgents() {
      processing.value = true;
      http.get(`/api/users?role=agent&fetch=all`).then(res => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function fetchShipments(id: any) {
      processing.value = true;

      http
        .get(
          `/api/agent-shipments?agent=${id}&start=${fD(
            start.value,
            "yyyy-MM-dd"
          )}&end=${fD(end.value, "yyyy-MM-dd")}`
        )
        .then(res => {
          console.log(res.data);
          shipments.value = res.data;
          processing.value = false;
        });
    }

    onMounted(() => {
      fetchAgents();
    });

    watch(
      () => end.value,
      (v: any) => {
        fetchShipments(agent_id.value);
      }
    );

    return {
      agent_id,
      agent,
      agents,
      start,
      end,
      fD,
      processing,
      shipments,
      fetchShipments,
      addCommas
    };
  }
});
